import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DownloadApp from '../components/DownloadApp';
import ImproveYourLife from '../components/ImproveYourLife';
import { Link } from "gatsby"

export default () => (
  <Layout>
    <SEO title="Attract Money" />

    <div>
      <div className="section image-bg-section">
        <div className="container">
          <div className="content-width-medium">
            <h6 className="subheading">Attract Money</h6>
            <h1 className="no-bottom-space center-mobile">You can win the money game</h1><Link to="/pricing/" className="button margin-top w-inline-block">
              <div>Get started</div>
            </Link>
          </div>
        </div>
        <div style={{backgroundImage: 'url("/images/5ddc2888c8721ff8978519dd_dollars_t20_jowaxz.jpg")'}} className="image-background bg-image-1" />
        <div className="background-scrim-left" />
      </div>
      <div className="section">
        <div className="container">
          <div className="w-richtext">
            <p>One of the secrets to attracting and keeping more money is to have thought habits that attract wealth. Successful people envision wealth constantly, and how they’ll use it to enrich their lives, and the lives of people around them.</p>
            <p>These are precisely the habits and thoughts that The WiseGuide App will help you incorporate into your day and life. We will guide you through eliminating your limitations and engage your creativity to discover new ways you can earn more money. The WiseGuide App is designed to give you the confidence, commitment, and persistence to set and achieve financial goals beyond what you may currently think is possible!</p>
            <p>Start a FREE 7-day trial to experience how The WiseGuide App can help you win at the money game. Just listen</p>
          </div>
        </div>
      </div>
    </div>

    <ImproveYourLife />

    <DownloadApp />
  </Layout>
);
